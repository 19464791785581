.face-mesh-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  overflow: hidden;
}

canvas {
  -webkit-transform: scaleX(-1);
  transform: scale(-1, 1);
  position: absolute;
}