.fwd-header {
    height: 56px;
    width: 100% !important;
    padding: 15px 25px;
    margin: 0;
    top: 0;
    position: absolute;
    overflow: hidden !important;
    z-index: 1;
    border-radius: 0 0 16px 16px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.fwd-header > img {
    width: 73px;
    height: 26px;
    /* margin: 24px; */
}
