  #circular-progress-result {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* bottom: 80px;
     min-width: 54px;
     min-height: 54px; */
  }
  
  .d-flex {
    gap: 80px;
  }

  /* .description {
    font-size: 45px;
  } */

  .progress-result {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .container-result {
    display: flex;
  }

  .text-result {
    position: absolute;
    top: 33%;
    /* content: v-bind(inOutTextStyle); */
    /* left: 160px; */
  }

  text {
    font-size: 80px;
    color: #183028;
  }

  .progress-result-background {
    opacity: 1.0; /* 0.1; */
  }
  
  @keyframes textAnimation {
    0% {
      opacity: 1;
      font-size: 1.2em;
      content: var(--in);
    }
    45% {
      opacity: 1;
      font-size: 2.9em;
      content: var(--in);
    }
    46% {
      opacity: 1;
      font-size: 2.9em;
      content: var(--out);
    }
    90% {
      opacity: 1;
      font-size: 1.2em;
      content: var(--out);
    }
    91% {
      opacity: 1;
      font-size: 1.2em;
      content: var(--in);
    }
    100% {
      opacity: 1;
      font-size: 1.2em;
      content: var(--in);
    }
  }
  @keyframes textAnimation-mobile {
    0% {
      opacity: 1;
      font-size: 1em;
      content: var(--in);
    }
    45% {
      opacity: 1;
      font-size: 1.5em;
      content: var(--in);
    }
    46% {
      opacity: 1;
      font-size: 1.5em;
      content: var(--out);
    }
    90% {
      opacity: 1;
      font-size: 1em;
      content: var(--out);
    }
    91% {
      opacity: 1;
      font-size: 1em;
      content: var(--in);
    }
    100% {
      opacity: 1;
      font-size: 1em;
      content: var(--in);
    }
  }
  @keyframes circleAnimation {
    0% {
      opacity: 0.75;
      fill: #ffaa00;
      transform: scale(0.4);
    }
    50% {
      opacity: 0.9;
      fill: #00cc00;
      transform: scale(1);
    }
    100% {
      opacity: 0.75;
      fill: #ffaa00;
      transform: scale(0.4);
    }
  }
  @keyframes circleAnimation-mobile {
    0% {
      opacity: 0.75;
      fill: #ffaa00;
      transform: scale(0.3);
    }
    50% {
      opacity: 0.9;
      fill: #00cc00;
      transform: scale(0.9);
    }
    100% {
      opacity: 0.75;
      fill: #ffaa00;
      transform: scale(0.3);
    }
  }
  
  .breathing-circle-animation {
    opacity: 1;
    position: relative;
    left: 50%;
    top: 50%;
    color: white;
  }

  @media (max-width: 2000px) {
    .d-flex {
      gap: 10px;
    }

    .description {
      font-size: 18px;
    }

    text {
      font-size: 40px;
    }

    #get-vs-button-container {
      width: 90%;
      padding: 20px;
      top: 200px;
    }
  }
  
  @media (max-width: 1024px) {
    #get-vs-button-container {
      top: 160px;
    }
  }
  