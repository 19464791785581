.loading {
  position: absolute;
  font-size: x-large;
}

.loader {
  width: 24px;
  height: 24px;
  border: 3px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-right: 0.5em;
  vertical-align: -0.1em;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}