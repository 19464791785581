.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-weight: 900;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    width: 80%;
    max-width: 500px;
    padding: 20px;
    font-weight: 500;
    background-color: white;
    border-radius: 4px;
}

