.landing-page {
  position: static;
  height: 100vh;
  overflow: hidden;
  background-color: #DBDFE1;
  /* min-height: max-content; */
}

.circles-container {
  z-index: 0 !important;
}

button.landing-start-button-en, button.landing-start-button-en:hover{
  width: 10vw;
  height: 8.8vh;
  min-width: 136px;
  white-space: nowrap;
  font-size: 1.7rem;
  font-weight: 900;
  background: #FFFFFF;
  border: 2px solid #E87723;
  /* bottom: 0; */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10 !important;
  margin-top: 30vh;
  /* margin-bottom: 10%; */
  /* z-index: 10 !important; */
}

button.landing-start-button-zh, button.landing-start-button-zh:hover {
  width: 10vw;
  height: 8.8vh;
  min-width: 136px;
  white-space: nowrap;
  font-size: 2rem;
  font-weight: 900;
  background: #FFFFFF;
  border: 2px solid #E87723;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10 !important;
  margin-top: 30vh;
}

.button-container {
  /* padding-top: 100px; */
}

.landing-page-title-en {
  width: 100%;
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: -10% !important;
}

.landing-page-title-zh {
  width: 100%;
  font-size: 3.5rem;
  font-weight: 900 !important;
  margin-top: -10% !important;
}

.landing-page-subtitle-en {
  font-size: 1.7rem;
  font-weight: 500;
}

.landing-page-subtitle-zh {
  font-size: 2.3rem;
  font-weight: 600;
}

.landing-page-ellipse-innermost {
  width: 26vw;
  height: 26vw;
  background-size: 85% 85%;
  min-width: 274px;
  min-height: 274px;
  white-space: pre-wrap;
  background-image: url('assets/start-background.svg');
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.landing-page-ellipse-inner {
  width: 30vw;
  height: 30vw;
  min-width: 316px;
  min-height: 316px;
  border: 10px solid rgba(255, 255, 255, 0.9);
}

.landing-page-ellipse-outer {
  width: 33vw;
  height: 33vw;
  min-width: 346px;
  min-height: 346px;
  border: 5px solid rgba(255, 255, 255, 0.8);
}

.landing-page-ellipse-outermost {
  width: 37vw;
  height: 37vw;
  min-width: 386px;
  min-height: 386px;
  border: 5px solid rgba(255, 255, 255, 0.4);
}

.animals-container > img {
  position: absolute;
  bottom: -3%;
  width: 70vw;
  height: auto;
  max-width: 1024px;
}

.animals-container > img.animals-mobile {
  width: 100vw;
  height: auto;
  bottom: 0%;
}

.circles-bird {
  left: -40px;
  width: 35vw;
  min-width: 351px;
  min-height: 286px;
}

@media (max-width: 1800px) {
  .landing-page-title-en {
    font-size: 2rem;
  }

  .landing-page-title-zh {
    font-size: 2.6rem;
  }

  .landing-page-subtitle-en {
    font-size: 1.3rem;
    white-space: wrap;
  }

  .landing-page-subtitle-zh {
    font-size: 1.8rem;
    white-space: wrap;
  }

  button.landing-start-button-en, button.landing-start-button-en:hover {
    width: 9.5vw;
    height: 7.5vh;
    font-size: 1.5rem;
    margin-top: 25vh;
    /* margin-bottom: 5%; */
    /* margin-top: -10%; */
  }

  button.landing-start-button-zh, button.landing-start-button-zh:hover {
    width: 12vw !important;
    height: 9vh;
    font-size: 1.7rem;
    margin-top: 25vh;
  }

}

@media (max-width: 1024px) {
  .landing-page-title-en {
    font-size: 2rem;
    margin-top: -10% !important;
  }

  .landing-page-title-zh {
    font-size: 2.5rem;
    margin-top: -10% !important;
  }

  .landing-page-subtitle-en {
    font-size: 1.3rem;
  }

  .landing-page-subtitle-zh {
    font-size: 1.5rem;
  }

  button.landing-start-button-en, button.landing-start-button-en:hover {
    font-size: 20px;
    margin-top: 20vh;
    /* margin-bottom: 5%; */
    /* margin-top: -10%; */
  }

  button.landing-start-button-zh, button.landing-start-button-zh:hover{ 
    font-size: 25px !important;
    margin-top: 20vh;
  }
}

@media (max-width: 768px) {
  .circles-container {
    transform: scale(0.9)
  }

  .landing-page-title-en {
    font-size: 30px;
    margin-top: -15% !important;
  }

  .landing-page-subtitle-en {
    font-size: 16px;
    white-space: nowrap;
  }

  .landing-page-subtitle-zh {
    white-space: nowrap;
  }

  button.landing-start-button-en, button.landing-start-button-en:hover {
    height: 64px;
    font-size: 30px;
    z-index: 100;
    margin-top: 18vh;
    /* margin-bottom: 10%; */
    /* z-index: 100 !important; */
    /* position: absolute; */
  }

  button.landing-start-button-zh, button.landing-start-button-zh:hover {
    height: 64px;
    font-size: 25px !important;
    z-index: 100;
    margin-top: 18vh;
  }

  .button-container {
    /* z-index: 10 !important; */
  }
}

@media (max-width: 635px) {
  .landing-page-title-zh {
    font-size: 2.5rem;
  }

  button.landing-start-button-en, button.landing-start-button-en:hover {
    font-size: 18px;
    z-index: 100;
    margin-top: 18vh;
    /* margin-bottom: 50%; */
    /* margin-bottom: 10% !important; */
    /* position: absolute; */
    /* z-index: 10 !important; */
  }

  button.landing-start-button-zh, button.landing-start-button-zh:hover {
    font-size: 20px !important;
    z-index: 100;
    margin-top: 18vh;
  }


  .button-container {
    /* z-index: 10 !important; */
  }
}


.version {
  font-size: xx-small;
  position: absolute;
  left: 0px;
  bottom: 0px;
  opacity: 0.2;
  z-index: 999;
}