.checklist-container {
  border-radius: 24px;
  width: 95%;
  /* height: calc(98vh - 56px);
  height: 80vh;
  padding: 56px 80px; */
}

.condition-container {
  width: min-content;   /* 50%; */
  padding: 5px 0;
}

.condition {
  white-space: nowrap;
  font-weight: 500;
  font-size: 1.56rem;
   /* gap: 50px; */
}

.conditionSection {
  color: orange;
  margin-bottom: 5px;
  font-weight: bold;
  margin-top: 5px;
  flex: 1;
  min-width: 100%;
}

.icon {
/* height: 15px;
  width: 15px; */
}

.header {
  position: relative;
  height: 60%;
  gap: 24px;
  padding:24px;
  border-radius: 24px 24px 0px 0px;
  color: #183028;
  background-color: #E87722;
}

.scanning-icon {
  width: 130px;
  height: 130px;
}

.condition-checklist-message {
  font-size: 1.56rem; /* 25px; */
  font-weight: 500;
  white-space: nowrap;
}

.description {
  height: 40%;
  overflow: hidden;
  color: #183028;
  background-color: rgba(255,255,255,0.9);
  border-radius: 0px 0px 24px 24px;
  gap: 24px;
}

@media (max-width: 768px) {
  .checklist-container {
    margin-top: -25%;
  }
  .condition {
    font-size: 16px;
  }

  .condition-checklist-message {
    font-size: 16px; 
    font-weight: 500;
    white-space: pre-wrap;
  }
}
