.lucky-draw-page {
    white-space: pre-wrap;
}

.lucky-draw-page-title {
    padding: 0;
    font-size: 1.5rem;
    font-weight: 900;
    color: white;
}

.lucky-draw-page-description {
    font-size: 16px; /* 1rem; */
    font-weight: 500;
    color: white;
}

.lucky-draw-instructions-title {
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
}

.lucky-draw-page-subtitle {
    font-size: 1.5rem;
    font-weight: 500;
}

.outer {
    padding: 0 !important;
}

.lucky-draw-page-upper {
    background-color: #E87722;
}

.lucky-draw-page-lower {
    background-color: #F4F6F8;
}

.lucky-draw-form-title {
    font-weight: 500;
    font-size: 1.5rem;
    color: black;
}

.lucky-draw-steps-title {
    font-size: 0.8rem;
    font-weight: 500;
}

.lucky-draw-steps-description {
    font-size: 0.75rem; 
    font-weight: 450;
    white-space: wrap;
}

.instructions-box {
    width: 20vw;
    height: 28vh; /* 195px; 22vh */
    background-color: #F8F9F9;
    border-radius: 16px;
    overflow-y: auto;
}

.instructions-circle {
    width: 2.5vw;
    height: 2.5vw;
    min-width: 36px;
    min-height: 36px;
    background-color: #D9D9D9;
}

.lucky-draw-steps-icon {
    width: 3vw;
    height: auto;
    min-width: 60px;
}

.lucky-draw-icon-three {
    transform: scale(1.2);
}

.lucky-draw-form-button {
    width: 22vw;
    /* height: 4vh; */
    line-height: 1rem;
    font-size: 1.2em; /* 1rem; */
    font-weight: 500;
    border-radius: 4px;
}

.lucky-draw-confirm-button {
    color: white;
    background-color: #E87722;
    border: 2px solid transparent;
}

.lucky-draw-confirm-button:hover, .lucky-draw-confirm-button:focus {
    background-color: white;
    color: #E87722;
    border: 2px solid #E87722;
}

.lucky-draw-back-button {
    background-color: white;
    color: #E87722;
    border: 2px solid #E87722;
}

.tc-note {
    font-size: 0.75rem;
    font-weight: 500;
    color: white;
}

.arrow-icon {
  transform: scale(0.6);
  margin-left: 3px;
  margin-bottom: 3px;
}

.tc-note > a {
    text-decoration: none;
    color: inherit;
}

.tc-content {
    width: 87%;
}

.tc-prizes {
    line-height: 1em;
}

.tc-button {
    background-color: transparent;
    color: inherit;
}

.user-consent {
    font-size: 0.8rem;
    font-weight: 500;
    color: #5F6368;
}

.user-consent-footnote {
    font-size: 0.8rem;
    font-weight: 700;
    color: #5F6368;
}

.user-consent-footnote a {
    color: inherit; 
}

form > input {
    margin: 10px;
    padding: 10px;
}

input[type="text"] {
    /* width: 21vw; */
}

.form-group > label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #5F6368;
    /* height: 1.5rem; */
    white-space: nowrap;
}

input[type="tel"] {
    margin-top: 8px;
}

.form-error {
    font-size: 0.8rem;
    font-weight: 450;
    /* margin-left: 20px; */
}

.input-error {
    border: 2px solid #B30909;
}

@media (max-width: 1440px) {
    .instructions-box {
        /* height: 27vh; */
    }

    .lucky-draw-steps-description {
        font-size: 0.7rem;
    }
}

@media (max-width: 1200px) {
    .instructions-box {
        height: 35vh;
    }
    
    .lucky-draw-steps-description {
        font-size: 11px;
    }

    .form-group > label {
        white-space: pre-line;
    }

    input[type="tel"] {
        margin-top: 30px;
    }
}

@media (max-width: 1024px) {
    .instructions-box {
        height: 23vh;
        /* overflow-y: scroll; */
        /* font-size: 0.6em; */
    }
}

@media (max-width: 992px) {
    input[type="tel"] {
        margin-top: 8px;
    }
}

@media (max-width: 768px) {
    .lucky-draw-page {
        background-color: #F4F6F8;
    }

    .lucky-draw-page-title {
        margin-left: 25px; /* 30px */
        padding-top: 10px;
        text-align: left !important;

        font-weight: 700;
        font-size: 20px;
    }

    .lucky-draw-instructions-title {
        font-size: 18px;
        font-weight: 500;
    }

    .lucky-draw-page-description {
        margin-top: -10px;
        width: 87%;
        padding-left: 0px;
        padding-right: 0px;
        white-space: pre-line;
        font-size: 13px;
        font-weight: 500;
    }

    .instructions-box {
        width: 337px; 
        height: 215px;
        overflow-y: hidden;
    }

    .user-consent > label {
        margin: 0;
    }

    .user-consent-footnote {
        font-weight: 700;
        font-size: 13px;
    }

    .user-consent {
        font-weight: 400;
        margin-left: 20px;
        margin-right: 20px;
        white-space: wrap;
        font-size: 12px;
    }

    .lucky-draw-form-title {
        font-size: 24px;
        font-weight: 700;
        padding-top: 50px !important;
    }

    .lucky-draw-form-button {
        width: 300px;
        font-size: 13px !important;
        /* height: 40px; */
        font-weight: 600;
    }

    input[type="text"], input[type="email"], input[type="tel"] {
        width: 311px;
    }

    label {
        margin-left: 35px;
        font-size: 13px;
     
    }

    .form-group > label {
        white-space: nowrap;
    }

    .form-error {
        margin-left: 50px;
        font-size: 13px;
    }

    .checkbox-error {
        margin-left: 0px;
    }

    .lucky-draw-steps-title {
        font-size: 14px;
        font-weight: 500;
    }

    .lucky-draw-steps-description {
        font-size: 12px;
        font-weight: 450;
    }

    .lucky-draw-page-lower {
        padding-bottom: 150px;
    }

    .user-consent-footnote {
        margin-left: 30px;
        margin-right: 30px;
    }

    input[type="tel"] {
        margin-top: 8px;
    }
}

@media (max-width: 567px ) {
    input[type="text"], input[type="email"], input[type="tel"] {
        width: 90%;
    }

    .form-group > label {
        /* width: 100%; */
        /* border: 2px solid red; */
        margin-left: 5%;
        margin-right: auto;
        width: 90%;
    }
}