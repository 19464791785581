.container-fluid {
  overflow-x: hidden !important;
}

.resultContainer {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 30px;
  margin-left: 100px;
  margin-right: 100px;
  /* overflow: hidden; */
  overflow-y: scroll !important;
  overflow-x: hidden;
  background-color: white;
}

.resultContainer::-webkit-scrollbar {
  display: none;
}

.results-page-popup {
  width: 220px;
  height: 50px;
  padding: 13px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: white !important;
  background-color: #e87722;
  border-radius: 16px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 0;
}

.buttonsContainer {
  margin-top: 2rem;
}

.button {
  width: 10vw;
  height: 6vh;
  /* min-width: 100px; */
  /* min-height: 40px; */
  margin: 1rem;
  border-radius: 6.123px;
  border: 2px solid #E87722;
  color: #E87722;
  font-size: 1.2em; /* 1.5rem; */
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  background: #FFF;
  line-height: 1.5rem;
  padding: 0.05rem;
  overflow: clip;
}

.lucky-draw-button, .lucky-draw-button:hover {
  width: 31vw;
  height: 5.5vh;
  min-width: 324px;
  min-height: 40px;
  background: #E87722;
  color: white;
}

.button > img {
  width: 1.6vw;
  height: 1.6vw;
  min-width: 16px;
  min-height: 16px;
}

.close {
  text-align: left;
  color: white;
  padding-right: 5px;
  padding-top: 10px;
  cursor: pointer;
}

.animal-result {
  width: 105%;
  height: auto;
}

.animal-result-mobile {
  top: 100px;
  width: 393px;
  height: auto;
}

.square {
  width: 13vw;
  height: 13vw;
  /* min-width: 140px;
  min-height: 140px; */
  border-radius: 26.8px;
  background-color: #F8F9F9;
  overflow: clip;
}

.units {
  font-size: 1rem;
  font-weight: 500;
  color: #5F6368;
}

.vitals-label {
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap !important;
  color: rgba(16, 16, 16, 0.70);
}

.heart-rate, .stress, .respiratory-rate, .blood-pressure, .heart-rate-variability {
  font-size: 1.6rem;
  font-weight: 500;
  padding-left: 20px;
  color: #5F6368;
}

.general-wellness, .oxygen {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute !important;
  z-index: 1;
}

/* .general-wellness {
  font-size: 2rem;
  font-weight: 500;
  margin-top: -35%;
} */

.oxygen, .general-wellness {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: -35%;
  color: #5F6368;
}

img.stress-icon {
  width: 5.8vw;
  height: 5.8vw;
  min-width: 54px;
  min-height: 54px;
}

img.heart-rate-variability-icon, img.respiratory-rate-icon, img.heart-rate-icon {
  width: 8vw;
  height: 8vh;
  min-width: 92px;
  min-height: 54px;
}

img.blood-pressure-icon {
  width: 5vw;
  height: 8vh;
  min-width: 46px;
  min-height: 44px;
}

img.general-wellness-icon, img.oxygen-icon {
  width: 7.6vw;
  height: 7.6vw;
  min-width: 69px;
  min-height: 69px;
  /* z-index: -5; */
}

@media (max-width: 1440px) {
  .vitals-label {
    font-size: 0.9rem;
  }

  .stress-icon {
    transform: scale(0.9);
  }

  .general-wellness-icon, .oxygen-icon {
    transform: scale(0.9);
    margin-top: 10px;
  }

  /* .general-wellness {
    font-size: 1.8rem;
  } */
}

@media (max-width: 1200px) {
  .vitals-label {
    font-size: 0.8rem;
  }

  .heart-rate, .stress, .respiratory-rate, .blood-pressure, .heart-rate-variability {
    font-size: 21px;
  }

  .oxygen, .general-wellness {
    margin-top: -32%;
    font-size: 18px;
  }

  .stress-icon {
    transform: scale(0.8);
  }

  .general-wellness-icon, .oxygen-icon {
    transform: scale(0.7);
    margin-top: 5px;
  }

}

@media (max-width: 1024px) {
  .vitals-label {
    font-size: 12px;
  }

  img.heart-rate-variability-icon, img.respiratory-rate-icon, img.heart-rate-icon {
    margin-top: -18px;
  }

  img.blood-pressure-icon {
    margin-top: -20px;
  }

  button {
    font-size: 1em !important;
  }

  .square {
    width: 140px;
    height: 140px;
    border-radius: 26.8px;
    background-color:#F8F9F9;
    overflow: clip;
  }
}

.animalImageContainer {
  padding-left: 0px;
  padding-right: 0px;
}

.vitalSignsContainer {
  padding-top: 1em;
  padding-left: 5%;
  padding-right: 7%;
}

.vitalSignContainer {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

@media (max-width: 768px) {
  .resultContainer {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 60px;
  }

  .button {
    height: 40px;
    width: 100px;
    font-size: 13px !important;
  }

  .vitals-label {
    font-size: 11px;
    font-weight: 600;
    overflow: clip;
  }

  .units {
    font-size: 12px;
    font-weight: 600px;
  }

  .heart-rate, .stress, .respiratory-rate, .blood-pressure, .heart-rate-variability {
    font-size: 20px;
    font-weight: 600;
  }
  
  /* 
  .general-wellness {
    margin-top: -30%;
    font-size: 25px;
    font-weight: 500;
  } */

  .oxygen, .general-wellness {
    margin-top: -30%;
    font-size: 17px;
    font-weight: 600;
  }

  .stress-icon {
    transform: scale(0.8);
  }

  .general-wellness-icon, .oxygen-icon {
    /* transform: scale(0.78); */
    margin-top: 10px;
  }

  .lucky-draw-button, .lucky-draw-button:hover {
    height: 40px;
    margin-bottom: 25%;
  }

  .results-page-popup {
    font-size: 15px;
    line-height: 23px;
  }

  .bufferSquare {
    margin-left: 0.6em;
  }

  .square {
    width: 140px;
    height: 140px;
    border-radius: 26.8px;
    background-color:#F8F9F9;
    overflow: clip;
  }
}

@media (max-device-width: 360px) and (max-device-height: 780px) {
  .square {
    width: 135px;
    height: 135px;
    border-radius: 26.8px;
    background-color: #F8F9F9;
    overflow: clip;
  }

}
