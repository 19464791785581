.tc-page {
    /* overflow: scroll !important; */
    background-image: url('assets/background-image.svg');
    padding-top: 10px;
}

.tc-page-description {
    height: 70vh; /* 750px */
    overflow-y: scroll !important;
}

.tc-page-description::-webkit-scrollbar {
    display: none;
}

/* div::-webkit-scrollbar {
    display: none;
} */

.tc-page-terms {
    /* opacity: 0.9 */
}

.tc-page-title {
    font-size: 1.5rem;
    font-weight: 500;
}

.tc-page-description {
    padding: 24px 24px 48px 24px;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 0px 0px 11px 11px;
    color: black  !important;
    background-color: white;
}

.tc-page-topbar {
    /* width: 676px; */
    height: 93px;
    padding: 24px;
    border-radius: 11px 11px 0px 0px;
    background-color: #E87722;
}

.tc-button-back, .tc-button-agree {
    width: 220px; /* 229px 20vw; 27vw; */
    height: 45px;
    padding: 10px;
    line-height: 1.25rem;
    font-weight: 500;
    font-size: 1.25rem;
    border-radius: 4px;
    background-color: white;
    border: 2px solid #e87722;
    color: #e87722;
}

.tc-button-agree:disabled {
    opacity: 0.7;
    border: none;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    background-color: #e87722;
}

.tc-button-back:active, .tc-button-agree:active, .tc-button-agree:enabled {
    color: white;
    background: #e87722;
}

@media (max-width: 768px) {
    .tc-page {
        /* padding: 16px; */
        padding-top: 0;
    }

    .tc-button-back, .tc-button-agree {
        width: 115px;
        font-size: 0.9rem;
        line-height: 0.9rem;
        padding: 0;
        white-space: nowrap;
    }

    .tc-page-description {
        font-size: 1rem;
        height: 75vh;
        /* height: 50vh  */
    }

    /* .tc-page-content {
    } */
}

/* @media (max-width: 428px) {
    .tc-page-description {
        height: 70vh;
    } 
} */

@media (max-width: 567px) {
    .tc-page-topbar {
        height: 75px;
    }

    .tc-page-topbar > p {
        margin: 0;
    }
    .main-tc-content {
        width: 100vw;
        height: 90vh;
        padding: 16px 16px 16px 16px;
    }

    .tc-page-header {
        margin-top: 17px;
    }

    .tc-page-description {
        font-size: 0.9rem;
        font-weight: 400;
    }
}

@media (max-width: 375px) { /* 360 */
    .tc-page-topbar {
        height: 70px;
    }
    .main-tc-content {
        width: 100vw;
        height: 80vh;
        padding: 16px 16px 16px 16px;
    }

    .tc-page-header {
        margin-top: -20px;
    }

    .tc-page-description {
        height: 70vh;
    }
}

/* 
@media (max-width: 360px) {
    .tc-page-description {
        height: 60vh;
    } 
} */