.statusContainer {
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: 80px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 100px;
  border-color: transparent;
}

/* .text { // scanning, analyzing status text
  margin-top: 50px;
  display: block;
  text-align: center;
  color: white;
  text-shadow: -1 0 20px black;
} */

.countdown {
  display: block;
  text-align: center;
  color: white;
  text-shadow: -1 0 20px black ;
}
