.videoContainer {
  width: 100vw !important;
  height: 100vh !important;
}

video {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
}

.scanning-page {
  overflow: hidden;
  position: relative;
}

.silhouette {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 110%;
  height: 110%; 
  transform: translate(-5%, -5%);
  object-fit: cover;
  object-position: center;
  /* z-index: 0; */
}

.error {
  position: absolute;
  display: flex;
  width: 100%;
  top: 8%;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
  font-size: 30px;
  color: white;
  text-shadow: 0px 0px 5px black;
  text-align: center;
  /* display: inline-block;
  position: absolute;
  font-size:xx-large;
  font-weight: bold;
  color: red;
  border-style: solid;
  border-width: 0.2em;
  padding: 1em;
  line-height: normal; */
}

.cameraSwitchButton {
  /* position: absolute;
  width: 95px;
  height: 70px;
  bottom: 60px;
  right: 40px;
  border-radius: 50%;
  border-color: transparent;
  color: black;
  background-color: white; */
  transform: scale(0.8);
  position: absolute;
  bottom: 10%;
  right: 3%;
}

/* .goBackButton {
  position: absolute;
  bottom: 60px;
  left: 40px;
  border-color: transparent;
  color: black;
  background-color: white;
} */

.startButton {
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: 40px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 100px;
  background-color: orange;
  z-index: 1;
}

.startButton:disabled {
  background-color: gray;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}


@media (max-width: 768px) {  
  .silhouette {
    width: 330%;
    height: 90%;
    transform: translate(-35%, -10%);
    margin-top: 10%;
    object-fit: fill;
    object-position: center; 
  }
}