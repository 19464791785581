body {
  background-color: #DBDFE1 !important;
}

.content {
  position: static;
  height: 100vh;
  overflow-y: hidden;
}

.analyzing-page-title {
  font-size: 2rem; 
  font-weight: 500px;
}

.analyzing-page-ellipse-innermost {
  width: 26vw; 
  height: 26vw; 
  min-width: 274px;
  min-height: 274px;
}

.analyzing-page-ellipse-inner {
  width: 30vw;
  height: 30vw; 
  min-width: 316px;
  min-height: 316px;
  border: 10px solid rgba(255, 255, 255, 0.9);
}

.analyzing-page-ellipse-outer {
  width: 33vw; 
  height: 33vw; 
  min-width: 346px;
  min-height: 346px;
  border: 5px solid rgba(255, 255, 255, 0.8);
}

.analyzing-page-ellipse-outermost {
  width: 37vw; 
  height: 37vw; 
  min-width: 386px;
  min-height: 386px;
  border: 5px solid rgba(255, 255, 255, 0.4);
}

@media (max-width: 768px) { 
  .analyzing-circles-container {
    transform: scale(0.9);
    margin-top: -50%;
  }

  .analyzing-page-title {
    font-size: 20px;
  }
}
