
:root {
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-family: FWDCircular !important;
  /* background-color: #242424; */
}

#root {
  width: 100vw;
  height: 100vh;
}

body {
  height: 100%;
  margin: 0;
  overflow: hidden; /* mobile */
  /* display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh; */
}

.screen {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black !important;
}

/* a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
} */
