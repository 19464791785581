.language-picker-wrapper {
    position: relative;
    top: 0.7em;
    right: 20px;
    height: 50px;
    margin-bottom: -50px;
}

.language-picker {
    position: absolute;
    top: 0px !important;
    right: 0px;
    z-index: 1;
    font-size: 0.875rem;
    font-weight: 700;
    background-color: transparent;
    z-index: 2;
}

.language-picker-lucky-draw {
    position: absolute;
    top: 5px !important;
    right: 5px;
    width: 138px;
    height: 32px;
    margin-right: -70px;
    padding-top: 5px;
    padding-left: 10px;
    z-index: 1;
    font-size: 0.875rem;
    font-weight: 700;
    border-radius: 16px 0 0 16px;
    background-color: white;
}

.language-separator {
    margin: 0px;
    color: #859D99;
}

.language-button {
    padding: 0 8px;
    font-size: 14px;
    font-weight: 700 !important;
    color: #859D99;
    background-color: transparent;
    border: none;
    line-height: 14px;
}

.active {
    color: #E87722;
}
