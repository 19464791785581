.lucky-draw-submitted-page {
    /* white-space: pre-wrap; */
    /* overflow-y: hidden !important; */
    overflow: scroll;
    background-color: #F4F6F8;
    padding-top: 150px;
    padding-bottom: 150px;
}

.top-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 70px;
    z-index: 0;
    background-color: #E87722;
}

.lucky-draw-submitted-title {
    font-size: 1.5rem;
    font-weight: 900;
    white-space: nowrap;
    color: white;
    z-index: 1;
}

.lucky-draw-submitted-message {
    font-weight: 500;
    font-size: 2rem;
    white-space: wrap;
    max-width: 90%;
    /* width: 324px; */
}

img.lucky-draw-submitted-icon {
    /* top: 50%; */
    margin-top: 10%;
}

.lucky-draw-submitted-button {
    width: 22vw;
    font-weight: 500;
    font-size: 1em; /* 1rem; */
    line-height: 1rem;
    border: 2px solid #E87722;
    border-radius: 4px;
}


.lucky-draw-submitted-back-button {
    height: 4vh;
    color: #E87722;
    margin-bottom: 1em;
    background-color: white;
}

.lucky-draw-submitted-share-button {
    min-height: 4vh;
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: #E87722;
    color: white;
}

.lucky-draw-submitted-back-button:hover,
.lucky-draw-submitted-back-button:focus {
    color: white;
    background-color: #E87722;
}

.lucky-draw-content {
    margin-top: -10%
}

.lucky-draw-submitted-page-popup {
    width: 220px;
    height: 50px;
    padding: 13px;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: white !important;
    background-color: #e87722;
    border-radius: 16px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 0;
    margin-top: 1em;
}
  
@media (max-width: 768px) {
    .top-bar {
        width: 393px;
        height: 68px;
    }

    .lucky-draw-submitted-title {
        margin-left: -70px !important;
        font-size: 16px;
        font-weight: 600;
    }

    .lucky-draw-submitted-message {
        font-weight: 600;
        white-space: pre-line; /* wrap */
        max-width: 100%;
        padding: 0;
        /* width: 324px !important; */
        /* transform: translateY(-100%) */
        /* top: -250px; */
        /* margin-top: -40%; */
    }

    .lucky-draw-submitted-button {
        position: relative;
        width: 300px;
        /* height: 45px; */
        /* top: 90%; */
        /* left: 50%;
        transform: translateX(-50%); */
        /* transform: translate(-50%, -50%); */
        font-weight: 600;
        font-size: 13px !important;
    }

    img.lucky-draw-submitted-icon {
        width: 97px;
        height: 187px;
    }

    /* .lucky-draw-content {
        margin-top: -100%;
        color: red; */
        /* margin-top: 30%
    } */
}

@media (max-width: 567px) {
    .lucky-draw-content {
        /* padding-top: */
        margin-top: -30vh;
        /* margin-top: -80%; ww */
        /* border: 2px solid red; */
        /* margin-top: -90%;
        color: blue; */
    };

}

@media (max-width: 375px) {
    .lucky-draw-content {
        margin-top: -15vh;
        /* margin-top: -70%; ww */
        /* margin-top: -50%; */
    }
}

#winner-list {
    min-width: 200px;
}
.tc-note {
    font-size: 0.75rem;
    font-weight: 500;
    color: black;
}

.arrow-icon {
  transform: scale(0.6);
  margin-left: 3px;
  margin-bottom: 3px;
}

.tc-note > a {
    text-decoration: none;
    color: inherit;
}

.tc-content {
    width: 87%;
}

.tc-prizes {
    line-height: 1em;
}

.tc-button {
    background-color: transparent;
    color: black;
}
